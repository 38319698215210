@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'mixin';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

a[href^=tel] {
   text-decoration:inherit;
   color: inherit;
}

/* NAV */

.top-bar{
	background-color: $red;
	position: fixed;
    width: 100%;
    z-index: 9;
	@include breakpoint(small down) {
	
	background-color: $snow;

	}

}

.menu-icon{
	color: $snow;
}

.title-bar{
	background-color: $red;
	position: fixed;
    width: 100%;
    z-index: 9;
	.title-bar-title{
		font-family: $dancing;
		font-size: 1.2em;
		a{
			color: $snow;
		}
	}
}

.top-bar{
	ul{
	background-color: $red;
	}
}

.menu{
	font-family: $dancing;
	font-size: 1.8em;
	background-color: $red;

	@include breakpoint(small down) {
	
	font-size: 1.5em;

	}

	li{
		background-color: $red;
		a{
		color: $snow;
		background-color: $red;
		border-radius: 15px;
		border: solid 1px $red;

		@include breakpoint(small down) {
			width: 100vw;
			text-align: center;
			background-color: $snow;
			color: red;
			border-radius: 0;
			border: none;

		}

			&:hover{
			transition: 0.15s ease-in;
			background-color: $snow;
			color: $red;
			}
		}
	}
}

/* HOME */

section.home{
	background-image: url("../img/Home_Franklin.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 70vh;

	.title{
		font-family: $dancing;
		color: $snow;
		font-size: 6em;
		@include breakpoint(small down) {
			font-size: 3.8em;

		}
	}

	.callout{
		border: none;
	}

	.intro{
		text-align: center;
		// background-color: rgba(255, 255, 255, 0.7);
		background-color: $snow;
		padding: 1%;
		color: $red;
		border-radius: 5px;
		@include breakpoint(medium down) {
		padding: 3%;

		}
	}

	.CTA{
		text-align: center;
		background-color: $red;
		padding: 0.5%;
		color: $snow;
		font-family: $dancing;
		font-size: 1.7em;
		border-radius: 5px;
		border: solid 1px $red;
		&:hover{
			background-color: $snow;
			transition: 0.15s ease-in;
			border: solid 1px $red;
			color: $red;
		}
	}
}

/* Perso */

section.perso{
	div.grid-container{
		div.grid-x.grid-margin-x.align-center{
			margin: 10%;
			div.cell.small-4.medium-2{
				img{
					
				}
			}
			div.cell.small-12.medium-6{
				h2{
					font-family: 'IBM Plex Sans', sans-serif;
					font-weight: 700;
					font-size: 3em;
				}
				p{
					font-family: 'IBM Plex Sans', sans-serif;
				}
			}
		}
	}
}

/* DH */

section.dh{
	div.grid-container{
		div.grid-x.grid-margin-x.align-center{
			margin: 10%;
			div.cell.small-12.medium-4{
				img{
					
				}
			}
			div.cell.small-12.large-8{
				h2{
					font-family: 'IBM Plex Sans', sans-serif;
					font-weight: 700;
					font-size: 3em;
				}
				p{
					font-family: 'IBM Plex Sans', sans-serif;
				}
			}
		}
	}
}

/* Horaire */

.horaire{
	background-color: $red;
	padding: 3% 0;

	h2{
		color: $snow;
		font-family: $dancing;
		font-size: 4em;
		padding: 0 0 3% 0 ;
	}

	.adress{
		color: $snow;
		padding: 3% 0 0;
	}

	.CTA{
		text-align: center;
		background-color: $snow;
		padding: 1% 5%;
		color: $red;
		font-family: $ibm;
		font-size: 1.7em;
		border-radius: 5px;
		&:hover{
			background-color: $red;
			transition: 0.15s ease-in;
			border: solid 1px $snow;
			color: $snow;
		}
	}
}

/* Menu */

.menu_type{
	ul{
		li{
			a{
				font-size: 2em;

				@include breakpoint(small down) {
				font-size: 1em;

				}
				&:hover{
					background-color: $gold;
					color: $snow;
				}
			}
		}
	}
	.is-active{
		a{
			background-color: $gold;
			color: $snow;
		}
	}
}

.tabs-content{
	border: none;
}

.tabs{
	border: none;
	li{
		a{
			font-family: 'Dancing Script', cursive;
			color: $gold;
			font-size: 1em;
		}
	}
}

.tabs-panel{
	padding: 0;
}

.titre{
	color: $gold;
	font-size: 4em;
	font-family: 'Dancing Script', cursive;
	background-color: transparent;
}

.menu__title{
	font-family: 'Dancing Script', cursive;
	font-size: 3em;
	&:after{
		background-image: url();
		width: 20px;
		height: 20px;
	}
}

.background__menu--white{
	padding: 5% 0;
		p.plat{
			font-family: 'IBM Plex Sans', sans-serif;
			color: $red;
		}
		.menu__title{
		color: $gold;
		}
}

.background__menu--red{
	background-color: $red;
	padding: 5% 0;
	color: $snow;
}

.baseline{
	color: #000;
	font-size: 0.8em;
	margin: -20px 0 10px;
}

/*EVENT*/

.banquet{
	margin: 5% 0;
}

.event{
	display: flex;
	flex-direction: column;
	padding: 2% 0;

	@include breakpoint(small down) {
		padding: 5% 5%;
	}

	.surtitle{
		font-family: $ibm;
		font-weight: 500;
		font-size: 1em;
		margin: 0px;
		@include breakpoint(small down) {
			font-size: 1em;
		}
	}

	.title{
		font-family: $ibm;
		font-weight: 700;
		font-size: 3.5em;
		@include breakpoint(small down) {
			font-size: 2.5em;
		}
	}

	.red{
		color: $red;
	}


	.case{
		padding: 2%;
		font-family: $ibm;

		@include breakpoint(small down) {
			padding: 10% 0 3%;
		}
	}

	.invers{
		flex-direction: row-reverse;
	}
	img{
		margin: 3% 0 0 0;


		@include breakpoint(small down) {
			margin: 2% 0;
		}
	}
}

/*Histoire*/
.histoire{
	display: flex;
	flex-direction: column;
	padding: 2% 0;

	@include breakpoint(small down) {
		padding: 5% 5%;
	}

	.title{
		font-family: $ibm;
		font-weight: 700;
		font-size: 3.5em;
		@include breakpoint(small down) {
			font-size: 2em;
		}
	}

	.case{
		padding: 2%;
		font-family: $ibm;

		@include breakpoint(small down) {
			padding: 10% 0 3%;
		}
	}

	.invers{
		flex-direction: row-reverse;
	}
	img{
		margin: 3% 0 0 0;


		@include breakpoint(small down) {
			margin: 2% 0;
		}
	}
}

/*FOOTER*/

.sponso{
	background-color: $red;
	padding: 3% 0;
}

.footer{
	background-color: $snow;
	padding: 2%;
	color: $red;
	font-family: $ibm;
	text-align: center;
	.title{
		font-family: $dancing;
		font-size: 4em;

		@include breakpoint(small down) {
		font-size: 2em;

		}
	}
	.ryan{
		color: $black;
	}
	a{
		color: $red;
		border-bottom: solid 2px $red; 

		@include breakpoint(small down) {
		background-color: $white;
		padding: 0;
		color: $red;
		border: solid 0px $red; 

		}

		&:hover{
			border-bottom: solid 2px $black;
		}
	}
}
































